import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteManyApi, getApi } from "services/api";

const initialState = {
  projects: [],
  selectedprojectsId:[],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));

export const fetchprojectsAsync = createAsyncThunk(
  "projects/fetchprojects",
  async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/projects/"
          : `api/projects/?createdBy=${user._id}`
      );
      console.log(response,"this is projects");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed projects");
    }
  }
);



export const deleteDealAsync = createAsyncThunk(
  "projects/deleteDeal",
  async (selectedprojectsId, { getState, rejectWithValue }) => {
    const { projects } = getState();
    
    try {
      console.log(projects.selectedprojectsId,"this is selected projects id s inside of the delete deal async")
      await deleteManyApi("api/deal/deleteMany",  projects.selectedprojectsId );
      return projects.selectedprojectsId;
    } catch (error) {
      return rejectWithValue("Failed to delete projects");
    }
  }
);


const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setDealIds: (state, action) => {
      state.selectedprojectsId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchprojectsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchprojectsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projects = action.payload;
      })
      .addCase(fetchprojectsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteDealAsync.pending, (state) => {
        console.log(state,"this is loading state")
        state.status = "loading";
      })
      .addCase(deleteDealAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
       console.log(action.payload,"payloads")
        // Remove the deleted projects from the state
        state.projects = state.projects.filter(

    (deal) =>  !action.payload.includes(deal._id.toString())
  );
        state.selectedprojectsId = []; // Reset selection after deletion
        console.log(state.projects,"this is succesed state")
      })
      .addCase(deleteDealAsync.rejected, (state, action) => {
        state.status = "failed";
        console.log(state,"this is failed state")
        state.error = action.payload;
      });
  },
});

export const selectprojects = (state) => state?.projects?.projects || [];
export const selectStatus = (state) => state?.projects?.status;
export const selectError = (state) => state?.projects?.error;
export const getSelectedprojectsId = (state) => state?.projects?.selectedprojectsId;
export const { setDealIds  } = projectsSlice.actions;

export default projectsSlice.reducer;
