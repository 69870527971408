import "./index.css";
import React, { Suspense, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import "assets/css/App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./index.css"
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import UserLayout from "layouts/user";
import { ChakraProvider, Spinner, Box } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { fetchRevenueReportsAsync } from "features/revenue-report/revenueReportSlice";
import { fetchInvoicesAsync } from "features/invoices/invoiceSlice";
import { fetchDealsAsync } from "features/deals/dealSlice";
import { fetchPropertiesAsync } from "features/properties/propertySlice";
import { fetchLocationsAsync } from "features/locations/locationSlice";
import { fetchLeadsAsync } from "features/Leads/leadSlice";
import { fetchContactsAsync } from "features/contacts/contactSlice";
import { fetchUsersAsync } from "features/users/userSlice";
import { fetchprojectsAsync } from "features/projects/projectSlice";

const fetchAllData = async () => {
  await Promise.all([
    store.dispatch(fetchRevenueReportsAsync()),
    store.dispatch(fetchInvoicesAsync()),
    store.dispatch(fetchDealsAsync()),
    store.dispatch(fetchPropertiesAsync()),
    store.dispatch(fetchLocationsAsync()),
    store.dispatch(fetchLeadsAsync()),
    store.dispatch(fetchContactsAsync()),
    store.dispatch(fetchUsersAsync()),
    store.dispatch(fetchprojectsAsync()),
  ]);
};

function App() {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token")||"h21ur1gr9712rh172rh1";

  const user = JSON.parse(localStorage.getItem("user"))
  // || {role:"admin"};
  useNavigate();

  return (
    <>
      <ToastContainer />
      <Routes>
        {token && user?.role ? (
          user?.role == "user"||user?.role == "agent" ? (
            <Route path="/*" element={<UserLayout />} />
          ) : user?.role === "admin" ? (
            <Route path="/*" element={<AdminLayout />} />
          ) : (
            ""
          )
        ) : (
          <Route path="/*" element={<AuthLayout />} />
        )}
      </Routes>
    </>
  );
}

function Root() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await fetchAllData();
      setLoading(false);
    };
    loadData();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <React.StrictMode>
          <ThemeEditorProvider>
            <Router>
              <Suspense fallback={<div>Loading...</div>}>
                {loading && (
                  <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="rgba(255, 255, 255, 0.8)"
                    zIndex="9999"
                  >
                    <Spinner size="xl" />
                  </Box>
                )}
                <App />
              </Suspense>
            </Router>
          </ThemeEditorProvider>
        </React.StrictMode>
      </Provider>
    </ChakraProvider>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(<Root />);