import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeadAdd from "components/ui/LeadsComponents/LeadAdd";
import LeadsGridTable from "../../components/ui/LeadsComponents/LeadsGridTable";
import { modalOpened } from "../../redux/slices/editModalSlice/editModalSlice";
import { Trash2 } from "lucide-react";
import { deleteLeadAsync, getSelectedLeadsId, assignLeadsToAgentAsync } from "./leadSlice";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { selectUsers } from "features/users/userSlice";

const Leads = () => {
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const selectedLeadsId = useSelector(getSelectedLeadsId);
  const users = useSelector(selectUsers)

  const handleOpen = () => {
    dispatch(modalOpened());
  };

  const handleDelete = () => {
    console.log(selectedLeadsId,"this is selected leads id")
    if (selectedLeadsId?.length > 0) {
      dispatch(deleteLeadAsync());
    } else {
      console.log("No deals selected for deletion");
    }
  };

  const handleAssignAgent = (agentId) => {
    if (selectedLeadsId?.length > 0 && agentId) {
      dispatch(assignLeadsToAgentAsync({ leadIds: selectedLeadsId, agentId }));
    } else {
      console.log("No leads selected or no agent chosen");
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4 flex justify-end items-center space-x-2">
        {selectedLeadsId?.length > 0 && (
          <>
            <Button
              variant="destructive"
              size="icon"
              onClick={handleDelete}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
            <Select onValueChange={handleAssignAgent}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Assign To" />
              </SelectTrigger>
              <SelectContent>
                {users.map((agent) => (
                  <SelectItem key={agent._id} value={agent._id}>
                    {agent.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </>
        )}
        <LeadAdd />
      </div>
      <div className="overflow-auto">
        <LeadsGridTable />
      </div>
    </div>
  );
};

export default Leads;
