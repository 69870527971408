import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import 'ag-grid-enterprise';
import { AgGridReact } from "ag-grid-react";
import { setLeadsId } from "features/Leads/leadSlice";
import { selectLeads } from "features/Leads/leadSlice";
import { selectLocations } from "features/locations/locationSlice";
import { selectUsers } from "features/users/userSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "services/api";

var checkboxSelection = function (params) {
  // we put checkbox on the name if we are not doing grouping
  return params.api.getRowGroupColumns().length === 0;
};

var headerCheckboxSelection = function (params) {
  // we put checkbox on the name if we are not doing grouping
  return params.api.getRowGroupColumns().length === 0;
};

const LeadsGridTable = () => {
  const leads = useSelector(selectLeads);
  const locations = useSelector(selectLocations);
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);

  const [subLocations, setSubLocations] = useState([]);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
console.log(subLocations, "this is subLocations");




const handleCheckboxSelection = (params) => {
  const selectedIds = params.api.getSelectedRows()?.map((row) => row._id);
  dispatch(setLeadsId(selectedIds));
};
const onRowClicked = (params) => {
  const selectedIds = params.api;
  console.log(selectedIds, "selectedIds");
};
const handleOpenModal = (event) => {
  setSelectedRowData(event.data);
  setIsEditModalOpen(true);
  // dispatch(modalOpened())
};
const handleClose = () => {
  setIsEditModalOpen(false);
  // dispatch(modalClosed());
};
const handleFileClick = (data) => {
  setSelectedFiles(data);
  setIsModalVisible(true);
};

const handleCancel = () => {
  setIsModalVisible(false);
};
  

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "_id",
      minWidth: 30,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      // headerCheckboxSelectionFilteredOnly: true,
      editable: false,
      onCellValueChanged: (event) => {
        setSelectedRowData(event.data);
        dispatch(setLeadsId(event.data));
      },
      minWidth: 20,
    },
    {
      field: "name",
      minWidth: 170,
      editable: true,
    },
    {
      field: "contactNo",
      minWidth: 170,
      editable: true,
    },
    {
      field: "type",
      minWidth: 170,
      editable: true,
    },
    {
      field: "assignTo",
      cellRenderer: (params) => {
        console.log(params, "this is params");
        const user = users.find(
          (user) => user._id === params.data.assignTo
        );

        if(user){
        
          console.log(user,params, "this is user");
        }
        return (
          <div>
            <h2>{user?.name}</h2>
          </div>
        );
      },
    },
    // {
    //   field: "userId.name",
    //   minWidth: 170,
    //   editable: true,
    // },

    {
      field: "category",
      minWidth: 170,
      editable: true,
    },

    {
      field: "budget",
      minWidth: 170,
      editable: true,
    },
    {
      field: "location",
      minWidth: 170,
      editable: true,
      cellRenderer: (params) => {
        const location = locations.find(
          (loc) => loc._id === params.data.location
        );
      
        return (
          <div>
            <h2>{location?.locationName}</h2>
          </div>
        );
      },
    },
    {
      field: "subLocation",
      minWidth: 170,
      editable: true,
      cellRenderer: (params) => {
        console.log(params, "this is params");
        const subLocation = subLocations.find(
          (loc) => loc._id === params.data.subLocation
        );

        if(subLocation){
        
          console.log(subLocation,params, "this is subLocation");
        }
        return (
          <div>
            <h2>{subLocation?.subLocationName}</h2>
          </div>
        );
      },
    },
   
    {
      field: "lookingarea",
      headerName: "Looking For",
      minWidth: 170,
      editable: true,
    },
    {
      field: "nxtreminder",
      headerName: "Next Reminder",
      minWidth: 170,
      editable: true,
    },
    
    {
      field: "interestedBeforeDate",
      minWidth: 170,
      editable: true,
    },
    // {
    //   field: "action",
    //   minWidth: 170,
    //   editable: true,
    // },
    {
      field: "remark",
      minWidth: 170,
      editable: true,
    },
    
    {
      field: "createBy",
      minWidth: 270,
      editable: false,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Group",
      minWidth: 170,
      field: "athlete",
      valueGetter: (params) => {
        if (params.node.group) {
          return params.node.key;
        } else {
          return params.data[params.colDef.field];
        }
      },
      headerCheckboxSelection: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const fetchSubLocations = async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/subLocation/"
          : `api/subLocation/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed deals");
    }
  };
  useEffect(() => {
    fetchSubLocations().then((data) => {
      setSubLocations(data);
    }
    );

  }, [])
  

  const onGridReady = useCallback(async (params) => {
    console.log("Grid is Ready");
  }, []);

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact
          rowData={leads}
          columnDefs={columnDefs}
          // autoGroupColumnDef={autoGroupColumnDef}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={"multiple"}
          editType={"fullRow"}
          rowGroupPanelShow={"always"}
          pivotPanelShow={"always"}
          pagination={true}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
          onSelectionChanged={handleCheckboxSelection}
        />
      </div>
    </div>
  );
};

export default LeadsGridTable;
