import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "services/api";
import { deleteManyApi, getApi } from "services/api";

const initialState = {
  locations: [],
  selectedLocationsId: [],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));

export const fetchLocationsAsync = createAsyncThunk(
  "locations/fetchLocations",
  async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/location/"
          : `api/location/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed locations");
    }
  }
);
export const addLocationAsync = createAsyncThunk(
  "locations/addLocation",
  async (locationData, { rejectWithValue }) => {
    try {
      const response = await postApi("api/location/add", locationData);
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to add location");
    }
  }
);

export const deleteLocationAsync = createAsyncThunk(
  "locations/deleteLocation",
  async (selectedLocationsId, { getState, rejectWithValue }) => {
    const { locations } = getState();

    try {
      console.log(
        locations.selectedLocationsId,
        "this is selected locations id s inside of the delete location async"
      );
      await deleteManyApi(
        "api/location/deleteMany",
        locations.selectedLocationsId
      );
      return locations.selectedLocationsId;
    } catch (error) {
      return rejectWithValue("Failed to delete locations");
    }
  }
);

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocationIds: (state, action) => {
      state.selectedLocationsId = action.payload;
    },
    addLocation: (state, action) => {
      state.locations.push(action.payload); // Mutation to add a new location
    },
    deleteLocation: (state, action) => {
      state.locations = state.locations.filter(
        (location) => !action.payload.includes(location._id.toString())
      );
      state.selectedLocationsId = []; // Reset selection after deletion
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLocationsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.locations = action.payload;
      })
      .addCase(fetchLocationsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addLocationAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        locationsSlice.caseReducers.addLocation(state, action); 
      })
      .addCase(deleteLocationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteLocationAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        locationsSlice.caseReducers.deleteLocation(state, action)
      })
      .addCase(deleteLocationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectLocations = (state) => state.locations.locations || [];
export const selectStatus = (state) => state.locations.status;
export const selectError = (state) => state.locations.error;
export const getSelectedLocationsId = (state) =>
  state?.locations?.selectedLocationsId;
export const { setLocationIds,addLocation,deleteLocation } = locationsSlice.actions;

export default locationsSlice.reducer;
